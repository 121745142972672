export const arrNav = {
  arrMenu: [
    {value: 'Trang chủ', isActive: true, id: 0, href: 'home-page'},
    {value: 'Form ứng tuyển', isActive: false, id: 1, href: 'form-careers'},
    {value: 'Vị trí tuyển dụng', isActive: false, id: 2, href: 'vacancies'},
    {value: 'Phúc lợi', isActive: false, id: 3, href: 'welfare'},
    {value: 'Về UniCloud', isActive: false, id: 4, href: 'about-us'},
  ],
};

export const arrImg = {
  arrSection: {
    imgSection: require('../images/layout/layout1.svg'),
    imgSection2: require('../images/layout/layout2.svg'),
    imgSection3: require('../images/layout/layout3.svg'),
  },
  arrSlides: [
    {imgSlide: require('../images/slide/img-slide1.png')},
    {imgSlide: require('../images/slide/img-slide2.png')},
    {imgSlide: require('../images/slide/img-slide3.jpg')},
    {imgSlide: require('../images/slide/img-slide4.png')},
  ],
  imgHeader: require('../images/header/section.svg'),
  iconArrow: require('../images/icon/Arrow_right_long.svg'),
  iconLocation: require('../images/icon/location-pin.svg'),
  imgLogo: require('../images/logo/logo.svg'),
  iconMessage: require('../images/icon/message_alt_fill.svg'),
  iconPhone: require('../images/icon/phone.svg'),
  iconPin: require('../images/icon/pin_fill.svg'),
};

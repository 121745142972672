import React, {useState, useEffect} from 'react';
import {Navbar, Nav} from 'react-bootstrap';

const isBrowser = typeof window !== 'undefined';
const isDocument = typeof document !== 'undefined';
const NavTopbar = () => {
  const [arrNav] = useState([
    {value: 'Trang chủ', isActive: true, id: 0, href: 'home-page'},
    {value: 'Form ứng tuyển', isActive: false, id: 1, href: 'form-careers'},
    {value: 'Vị trí tuyển dụng', isActive: false, id: 2, href: 'vacancies'},
    {value: 'Phúc lợi', isActive: false, id: 3, href: 'welfare'},
    {value: 'Về UniCloud', isActive: false, id: 4, href: 'about-us'},
  ]);
  const handelActiveMenu = (id) =>
    arrNav.map((nav) => (nav.isActive = id === nav.id ? true : false));
  useEffect(() => {
    const sections = isDocument ? document.querySelectorAll('section') : '';
    const navLi = isDocument
      ? document.querySelectorAll('nav .container .navbar-top a')
      : '';
    if (isBrowser) {
      let urlCurrent = window.location.hash;
      urlCurrent = urlCurrent.slice(1, urlCurrent.length, 1);
      navLi.forEach((li) => {
        li.classList.remove('is-active-link');
        if (li.classList.contains(urlCurrent)) {
          li.classList.add('is-active-link');
          window.history.pushState(urlCurrent, 'Title', `#${urlCurrent}`);
        }
      });
      arrNav.forEach((nav) => {
        nav.isActive = urlCurrent === nav.href ? true : false;
      });
    }
    document.addEventListener(
      'scroll',
      () => {
        let current = '';
        if (isBrowser) {
          sections.forEach((section) => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;
            if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
              current = section.getAttribute('id');
            }
          });
          navLi.forEach((li) => {
            console.log('start scroll 3');
            li.classList.remove('is-active-link');
            if (li.classList.contains(current)) {
              li.classList.add('is-active-link');
              window.history.pushState(current, 'Title', `#${current}`);
            }
          });
        }
      },
      true,
    );
  }, [arrNav]);
  return (
    <div className="menu-main">
      <Navbar bg="light" variant="light" className="menu-main-wrap">
        <div className="container container-wrap menu-main-wrap__content">
          <Navbar.Brand href="#home-page">
            <img
              alt=""
              src="../../logo.png"
              className="d-inline-block align-top logo-wrap"
              height="45px"
            />
          </Navbar.Brand>
          <Nav className="ml-auto navbar-top">
            {arrNav &&
              arrNav.map((nav) => {
                return (
                  <Nav.Link
                    key={nav.id}
                    href={`#${nav.href}`}
                    className={`navbar-item ${nav.href} ${
                      nav.isActive ? 'is-active-link' : ''
                    } `}
                    onClick={() => handelActiveMenu(nav.id)}
                  >
                    <span>{nav.value}</span>
                    <div
                      className={`item-border ${
                        nav.isActive ? 'is-active' : ''
                      }`}
                    ></div>
                  </Nav.Link>
                );
              })}
          </Nav>
        </div>
      </Navbar>
    </div>
  );
};
export default NavTopbar;

import React, {useContext, useEffect} from 'react';
import {slide as Menu} from 'react-burger-menu';
import {Navbar} from 'react-bootstrap';

import PortfolioContext from '../context/context';

const isBrowser = typeof window !== 'undefined';
const isDocument = typeof document !== 'undefined';
const NavBarMobile = () => {
  const {arrNavs} = useContext(PortfolioContext);
  const {arrMenu} = arrNavs;
  const handelActiveMenu = (id) =>
    arrMenu.map((nav) => (nav.isActive = id === nav.id ? true : false));
  useEffect(() => {
    const sections = isDocument ? document.querySelectorAll('section') : '';
    const navLi = isDocument ? document.querySelectorAll('nav .bm-item') : '';
    if (isBrowser) {
      let urlCurrent = window.location.hash;
      urlCurrent = urlCurrent.slice(1, urlCurrent.length, 1);
      navLi.forEach((li) => {
        li.classList.remove('is-active-mobile');
        if (li.classList.contains(urlCurrent)) {
          li.classList.add('is-active-mobile');
          window.history.pushState(urlCurrent, 'Title', `#${urlCurrent}`);
        }
      });
      arrMenu.forEach((nav) => {
        nav.isActive = urlCurrent === nav.href ? true : false;
      });
    }
    document.addEventListener(
      'scroll',
      () => {
        console.log('sctart sroll');
        let current = '';
        if (isBrowser) {
          sections.forEach((section) => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;
            if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
              current = section.getAttribute('id');
            }
          });
          navLi.forEach((li) => {
            console.log('start scroll 3');
            li.classList.remove('is-active-mobile');
            if (li.classList.contains(current)) {
              li.classList.add('is-active-mobile');
              window.history.pushState(current, 'Title', `#${current}`);
            }
          });
        }
      },
      true,
    );
  }, [arrMenu]);
  return (
    <>
      <Navbar className="navbar-mobile">
        <Navbar.Brand href="#home-page">
          <img
            alt=""
            src="../../logo.png"
            className="d-inline-block align-top logo-wrap"
            height="45px"
          />
        </Navbar.Brand>
      </Navbar>
      <Menu>
        {arrMenu &&
          arrMenu.map((nav) => {
            return (
              <a
                key={nav.id}
                id={nav.id}
                className={`menu-item ${
                  nav.isActive ? 'is-active-mobile' : ''
                } ${nav.href}`}
                href={`#${nav.href}`}
                onClick={() => handelActiveMenu(nav.id)}
              >
                {nav.value}
              </a>
            );
          })}
      </Menu>
    </>
  );
};
export default NavBarMobile;

import * as React from 'react';
import {Helmet} from 'react-helmet';

import LadingPage from './landing-page';
import Layout from '../components/layout';

import './styles.scss';
import '../styles/main.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Careers Ươm mầm Dev</title>
        <meta name="description" content={'Careers Ươm mầm Dev'} />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script src="https://apis.google.com/js/api.js" async defer></script>
        <script
          src="https://apis.google.com/js/client:plusone.js"
          type="application/javascript"
          async
          defer
        ></script>
      </Helmet>
      <main>
        <Layout>
          <LadingPage />
        </Layout>
      </main>
    </>
  );
};

export default IndexPage;

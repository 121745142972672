import React, { useState, useEffect } from "react";
import iconRounded from "../images/icon/Arrow_top.svg";
const isBrowser = typeof window !== "undefined";
const ButtonRounded = () => {
    const [isScroll, setIsScroll] = useState(false);
    useEffect(() => {
        if (isBrowser) {
            window.addEventListener("scroll", () => {
                if (window.scrollY > 100) {
                    setIsScroll(true);
                } else {
                    setIsScroll(false);
                }
            }, true);
        }
        return () => {
            setIsScroll(true);
        }

    }, [])
    return (
        <>
            {isScroll && (<a href="#home-page"><div className="btn-rounded" style={{ display: isScroll }}>
                <img src={iconRounded} alt="icon arrow top" />
            </div></a>)}
        </>
    );
}
export default ButtonRounded;

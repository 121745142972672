import React, {useEffect, useState} from 'react';

import NavBar from './navbar';
import Footer from './footer';
import BtnRounded from './btn-rounded';
import {PortfolioProvider} from './../context/context';
import {arrNav, arrImg} from '../mock/data';
import NavBarMobile from './navbar-mobile';

import './style.scss';
const isBrowser = typeof window !== 'undefined';
const Layout = ({children}) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [arrNavs, setNavData] = useState({});
  const [arrSection, setArrSection] = useState({});
  useEffect(() => {
    setNavData({...arrNav});
    setArrSection({...arrImg});
    if (isBrowser) {
      window.addEventListener('resize', () => {
        if (window.screen.width > 769) {
          setIsDesktop(true);
          setIsMobile(false);
        } else {
          setIsDesktop(false);
          setIsMobile(true);
        }
      });
      if (window.screen.width > 769) {
        setIsDesktop(true);
        setIsMobile(false);
      } else {
        setIsDesktop(false);
        setIsMobile(true);
      }
    }
  }, [isMobile, isDesktop]);
  return (
    <>
      <PortfolioProvider value={{arrNavs, arrSection}}>
        {isMobile && <NavBarMobile />}
        <div className="page">
          {isDesktop && <NavBar />}
          <div className="page-main" id="page-main">
            {children}
          </div>
          <BtnRounded />
          <Footer />
        </div>
      </PortfolioProvider>
    </>
  );
};
export default Layout;
